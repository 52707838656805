body .MuiPaper-elevation1{
    box-shadow: 0px 5px 15px rgba(0, 0, 0, 0.07);
}

body{
    /* background: #bf267a; */
    background-repeat: no-repeat;
    background-size: cover;
}

.form-container{
    background-color: rgba(255, 255, 255, 0.8);
    border-radius: 12px;
}

* {
    box-sizing: border-box;
  }

  .bottonFloat{
    position:'fixed';
    bottom:'0';
		right:'0'; 
    margin:'20px'
  }
  
  .fab-wrapper {
    position: fixed;
    bottom: 3rem;
    z-index: 8;
    right: 3rem;
  }
  .fab-checkbox {
    display: none;
  }
  .fab {
    position: absolute;
    bottom: -1rem;
    right: -1rem;
    width: 4rem;
    height: 4rem;
    background: blue;
    border-radius: 50%;
    background: #126ee2;
    box-shadow: 0px 5px 20px #81a4f1;
    transition: all 0.3s ease;
    z-index: 9;
    border-bottom-right-radius: 6px;
    border: 1px solid #0c50a7;
  }
  
  .fab:before {
    content: "";
    position: absolute;
    width: 100%;
    height: 100%;
    left: 0;
    top: 0; z-index: inherit;
    border-radius: 50%;
    background-color: rgba(255, 255, 255, 0.1);
  }
  .fab-checkbox:checked ~ .fab:before {
    width: 90%;
    height: 90%;
    left: 5%;
    top: 5%;
    background-color: rgba(255, 255, 255, 0.2);
    z-index: 5;
  }
  .fab:hover {
    background: #2c87e8;
    box-shadow: 0px 5px 20px 5px #81a4f1;
  }
  
  .fab-dots {
    position: absolute;
    height: 8px;
    width: 8px;
    background-color: white;
    border-radius: 50%;
    top: 50%;
    transform: translateX(0%) translateY(-50%) rotate(0deg);
    opacity: 1;
    animation: blink 3s ease infinite;
    transition: all 0.3s ease;
  }
  
  .fab-dots-1 {
    left: 15px;
    animation-delay: 0s;
  }
  .fab-dots-2 {
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    animation-delay: 0.4s;
  }
  .fab-dots-3 {
    right: 15px;
    animation-delay: 0.8s;
  }
  
  .fab-checkbox:checked ~ .fab .fab-dots {
    height: 6px;
  }
  
  .fab .fab-dots-2 {
    transform: translateX(-50%) translateY(-50%) rotate(0deg);
  }
  
  .fab-checkbox:checked ~ .fab .fab-dots-1 {
    width: 32px;
    border-radius: 10px;
    left: 50%;
    transform: translateX(-50%) translateY(-50%) rotate(45deg);
  }
  .fab-checkbox:checked ~ .fab .fab-dots-3 {
    width: 32px;
    border-radius: 10px;
    right: 50%;
    transform: translateX(50%) translateY(-50%) rotate(-45deg);
  }
  
  @keyframes blink {
    50% {
      opacity: 0.25;
    }
  }
  
  .fab-checkbox:checked ~ .fab .fab-dots {
    animation: none;
  }
  
  .fab-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    width: 20rem;
    height: 30rem;
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
    z-index: inherit;
  }
  
  .fab-checkbox:checked ~ .fab-wheel {
    transform: scale(1);
  }
  .fab-action {
  
    position: absolute;
    background: #0f1941;
    width: 3rem;
    height: 3rem;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    color: White;
    box-shadow: 0 0.1rem 1rem rgba(24, 66, 154, 0.82);
    transition: all 1s ease;
    z-index: inherit;
    opacity: 0;
  }
  
  .fab-checkbox:checked ~ .fab-wheel .fab-action {
    opacity: 1;
  }
  
  .fab-action:hover {
    background-color: #f16100;
  }
  
  .fab-wheel .fab-action-1 {
    right: -1rem;
    top: 0;
  }
  
  .fab-wheel .fab-action-2 {
    right: 3.4rem;
    top: 0.5rem;
  }
  .fab-wheel .fab-action-3 {
    left: 0.5rem;
    bottom: 3.4rem;
  }
  .fab-wheel .fab-action-4 {
    left: 0;
    bottom: -1rem;
  }
  .input{
    width: 100%;
    padding:10;
    
    /* position: absolute; */
  }

  .bkground{
      background-color: rgba(255,255,255,.9);
      padding: 30;
      height: 20rem;
      width:20rem;  
      border-radius: 5px;
      z-index: 5;
  }
  
  @media screen and (max-width:800px){
      
  .fab-wheel {
    position: absolute;
    bottom: 0;
    right: 0;
    border: 1px solid transparent;
    width: 15rem;
    height: 25rem;
  
    transition: all 0.3s ease;
    transform-origin: bottom right;
    transform: scale(0);
  }

  .bkground{
    background-color: rgba(255,255,255,.9);
    padding: 30;
    height: 20rem;
    width:20rem;  
    border-radius: 5px;
    padding-right:20px;
    z-index: 5;
}
  }

  .cancel{
    color:red;
    font-size: 16;
  }
  .body{
    margin-top: 100px;
    padding: 0, 130px;
    align-items: center;
    /* background-color: rgba(255,255,255,.9); */
  }

  .background1{
    width: 90%;
    margin-top: 100px;
    background-color: rgba(255,255,255,.9);
  
    height: 100vh;
  }
.search{
  width:100%;
  margin: 10px 180px;
}

.cards{
  width:50%
}
.input_features{
  width: 50%!important;
  margin-left: auto;
}
.addfeatures {
  display: flex;
  flex-direction: row;
}

.btnadd{
  height: 30px;
  position: relative;
  top: 23px;
  left: 12px;
}

 @media screen and (max-width:800px){
   .search{
     width:100%;
     background-color: red;
     margin: 10px 0px;
   }
   .cards{
     width:100%
   }
 }

.plancard{
  min-height:450px;
  width:100%;
  background-color: white;
  color: #091337;
  background-image: linear-gradient(56deg, #008dec 50%, #091337 100%);
  background-image: linear-gradient(56deg, #c508ff 0%, #23e3ee 100%) 
}

@media only screen and (max-width: 1024px) {
  makeStyles-no_hospital-15 {
    left: '50%',
  }
  .side_space{
    display: none
  }
}

.prev,.next{
  background: #f64e60;
  color:rgba(255, 255, 255, 0.8);
  border-radius: 50%;
  padding: 3px;
  box-shadow: -1px 1px 5px grey;
}
.disablePrevNext{
  background: #ddcacc;
  color: #8b1c27;
  border-radius: 50%;
  box-shadow: -1px 1px 5px grey;
}

.dropdown {
  float: left;
  overflow: hidden;
}

.dropdown .dropbtn {
  font-size: 18px;  
  border: none;
  outline: none;
  color: #091337;;
  padding: 8px 16px;
  /* font-weight: 500; */
  background-color: inherit;
  font-family: inherit;
  margin: 0;
}

/* #drp1,#drp2{
  display: none;
  position: fixed;
  background-color: #ffffff;
  top: 100px;
  z-index: 3031!important;
}
#drp1{
  right: 22.5%;
  min-width: 180px;
}
#drp2{
  right: 21.5%;
  min-width: 240px;
} */

.mobiledropbtn{
  display: none;
}

@keyframes example {
  from {top: 90px;}
  to {top: 103px;}
}
.dropdown-content, .hmo_dropdown_content{
  display: none;
  position: fixed;
  background-color: #ffffff;
  top: 103px;
  /* box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2); */
  z-index: 3031!important;
  animation-name: example;
  animation-duration: 1s;
  animation-timing-function: cubic-bezier(0.25, 1, 0.255, 1);
}
.dropdown-content{
  right: 38%;
  min-width: 180px;
}
/* @media only screen and (max-width: 991px) {
  .hmo_dropdown_content,.dropdown-content{
    display: none!important
  }
} */
@media only screen and (min-width: 1024px) and ( max-width:1440px) {
  .mobile_nav{
    display: none!important;
  }
}

@media only screen and (min-width: 960px) {
   .pdfdocx canvas{
    width: 80%!important;
    height: 60%!important;
  }
}
.hmo_dropdown_content{
  right: 31%;
  min-width: 200px;
}

@media only screen and ( max-width:1440px) {
  .hmo_dropdown_content {
    right: 31.2%;
    min-width: 100px
  }
  .dropdown-content {
    right: 36%
  }
}

@media only screen and (max-width: 1024px), (max-width: 1280px) {
  .hmo_dropdown_content{
    right: 31%!important;
    min-width: 100px!important;
  }
  .dropdown-content {
    right:38%!important;
  }
}

.hmo_mobile_nav_content{
  display: none;
}

.mobile_nav{
  display: none;
  position: relative;
  background-color: #f9f9f9;
}

@media only screen and ( max-width: 991px) {
  .dropbtn, .dropdown-content, .hmo_dropdown_content {
    display: none!important
  }
  .dropdown .mobiledropbtn{
    display: block;
    font-size: 18px;  
    border: none;
    outline: none;
    color: #091337;
    padding: 8px 16px;
    font-weight: 500;
    background-color: inherit;
    font-family: inherit;
    margin-left: auto;
    margin-right: auto;
  }
  .mobile_nav, .hmo_mobile_nav_content{
    display: none;
    position: relative;
    flex-direction: column;
    background: #bf267a;
  }
    .pdfdocx canvas{
    width: 100%!important;
    height: 100%!important;
  }
  
}

.mobile_nav a, .hmo_mobile_nav_content a{
  color: #ffffff!important;
  padding: 12px 16px;
  text-decoration: none;
}

.dropdown-content a, .hmo_dropdown_content a  {
  float: none;
  color: #091337;
  padding: 12px 16px;
  text-decoration: none;
  display: block;
  text-align: left;
}

.dropdown-content a:hover, .hmo_dropdown_content a:hover {
  background-color: #348ec6;
  color: #ffffff;
}

.dropdown:hover .dropdown-content {
  display: block;
}

.balance {
  width: 50%;
  text-align: right
}
@media screen and (max-width: 425px) {
  .balance {
    text-align: left
  }
}
.balance p:first-child{
  font-weight: 600;
  font-size: 20px;
  color:rgb(87, 86, 86)
}

  .file_input{
    color: transparent;
    width: 50% !important
  }

  .file_input::-webkit-file-upload-button {
    visibility: hidden;
  }

  .file_input::before {
    content: "UPLOAD IMAGE" !important;
    color: white;
    display: inline-block;
    background-color: #c7387b;
    /* background: -webkit-linear-gradient(top, #f9f9f9, #e3e3e3); */
    border: 0px solid #999;
    border-radius: 3px;
    padding: 5px 3px;
    outline: none;
    white-space: nowrap;
    -webkit-user-select: none;
    cursor: pointer;
    /* text-shadow: 1px 1px #fff; */
    font-weight: 400;
    font-size: 10pt;
    text-align: center;
  }
  .file_input:hover::before {
    border-color: #3f51b5;
  }
  .file_input:active {
    outline: 0;
  }
  .file_input:active::before {
    background: -webkit-linear-gradient(top, #e3e3e3, #f9f9f9); 
  }

  @media (max-width: 1278px){
    .file_input{
      width: 20% !important
    }
  }
  @media (max-width: 1278px){
    .file_input{
      width: 30% !important
    }
  }

  @media (min-width: 320px) and (max-width:474px){
    .file_input{
      width: 60% !important
    }
  }

  .page_jump{
    /* border: 1px solid; */
    display: flex;
    /* padding: 14px 10px; */
    line-height: 17px;
    /* background-color: #f64e60; */
  }

  /* .page_jump span::hover{
    display: block
  } */

  .page_jump,.not_page_jump{
    font-size: 20px;
    font-weight:  700;
    border-radius: 50%;
    width: 100px;
    color: #000;
    cursor: pointer;
  }

  .page_jump span, .not_page_jump span{
    display:none
  }

  .page_jump:hover span, .not_page_jump:hover span{
    display:block
  }

  .not_page_jump{
    border: 0px solid;
    padding: 4px;
    line-height: 19px;
    display: flex;
    flex-direction: row;
    /* background-color: #ddcacc; */
    color: #8b1c27;
  }

  .slidein{
    display: block;
  }
  .slideout{
    display: none;
  }

  #table thead tr th:last-child{
    width: 25%!important;
  }

  .total_amount{
    justify-content: space-between;
    border-top: 1px solid #e5e6ec;
    margin-top: 1rem;
  }
  .radio_container, .total_amount, .admitted{
    display: flex;
    flex-direction: row!important;
  }

  .admitted{
    justify-content: center;
  }

  .admitted :first-child{
    margin-right: 10px;
  }

  .admitted :last-child{
    margin-left: 10px;
  }

  #invoice_table tbody tr td:last-child{
    padding-left: 0;
    padding-right: 0;
  }

  .hmo-contact{
    display: flex;
    flex-direction: column;
    position: relative;
    /* top: -3%;
    left: 16px; */
    margin: 0;
    padding: 0;
  }

  .hmo-contact span{
    width: fit-content;
    font-size: 12px;
  }
  
  /* .invoice_root{
    background: #fff;
    width: 60%;
    border-radius: 25px;
    padding: 20px;
  } */

  .side_profile{
    display: flex;
    flex-direction: column;
  }
.pdfdocx{
    align-items: center;
    display: flex;
    flex-direction: column;
}


